import React from 'react';
import SideBarNewsCategory from '../SideBarNewsCategory/SideBarNewsCategory';
import SideBarNewsRecentPosts from '../SideBarNewsRecentPosts/SideBarNewsRecentPosts';
import SideBarNewsTags from '../SideBarNewsTags/SideBarNewsTags';
import SideBarNewsInstaFeeds from '../SideBarNewsInstaFeeds/SideBarNewsInstaFeeds';
import SideBarNewsLetter from '../SideBarNewsLetter/SideBarNewsLetter';
import './SideBarNews.css';

class SideBarNews extends React.Component {
  render() {
    return (
      <>

        <SideBarNewsCategory />
        <SideBarNewsRecentPosts />
        <SideBarNewsTags />
        <SideBarNewsInstaFeeds />
        <SideBarNewsLetter />

      </>
    )
  }
}

export default SideBarNews;