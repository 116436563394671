import React from 'react';
import './SideBarNewsTags.css';

class SideBarNewsTags extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget tag_cloud_widget">
          <h4 class="widget_title" style={{ color: '#2d2d2d' }}>Tag Clouds</h4>
          <ul class="list">
            <li>
              <a href="#">project</a>
            </li>
            <li>
              <a href="#">love</a>
            </li>
            <li>
              <a href="#">technology</a>
            </li>
            <li>
              <a href="#">travel</a>
            </li>
            <li>
              <a href="#">restaurant</a>
            </li>
            <li>
              <a href="#">life style</a>
            </li>
            <li>
              <a href="#">design</a>
            </li>
            <li>
              <a href="#">illustration</a>
            </li>
          </ul>
        </aside>

      </>
    )
  }
}

export default SideBarNewsTags;