import React from "react";
import { Link } from "react-router-dom";
import "./Gallery.css";
import image1 from "../../plantpicture/image1.jpg";
import image2 from "../../plantpicture/image2.jpg";
import image3 from "../../plantpicture/image3.jpg";
import image4 from "../../plantpicture/image4.jpg";
import image5 from "../../plantpicture/image5.jpg";
import image6 from "../../plantpicture/image6.jpg";
import image7 from "../../plantpicture/image7.jpg";
import image8 from "../../plantpicture/image8.jpg";
class Gallery extends React.Component {
  render() {
    return (
      <div>
        <div>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link
            rel="stylesheet"
            href="https://www.w3schools.com/w3css/4/w3.css"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Raleway"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\nbody,h1 {font-family: "Raleway", Arial, sans-serif}\nh1 {letter-spacing: 6px}\n.w3-row-padding img {margin-bottom: 12px}\n',
            }}
          />
          {/* !PAGE CONTENT! */}
          <div className="w3-content" style={{ maxWidth: "1500px" }}>
            {/* Header */}
            <header
              className="w3-panel w3-center w3-opacity"
              style={{ padding: "128px 16px" }}
            >
              <h1 className="w3-xlarge">Plant Pictures</h1>
            </header>
            {/* Photo Grid */}
            <div
              className="w3-row-padding w3-grayscale"
              style={{ marginBottom: "128px" }}
            >
             <div className="w3-content w3-padding-large w3-margin-top" id="portfolio">
          {/* Images (Portfolio) */}
          <img src={image1} alt="Ocean" className="w3-image" width={1000} height={500} />
          <img src={image2} alt="Ocean II" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image3} alt="Falls" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image4} alt="Skies" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image5} alt="Mountains" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image6} alt="Mountains" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image7} alt="Mountains" className="w3-image w3-margin-top" width={1000} height={500} />
          <img src={image8} alt="Mountains" className="w3-image w3-margin-top" width={1000} height={500} />
 
        </div>
            </div>
            {/* End Page Content */}
          </div>
        </div>

        {/* Solutions End */}
        <div id="back-top">
          <a title="Go to Top" href="#">
            {" "}
            <i class="fas fa-level-up-alt"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Gallery;
