/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Home.css";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? slider Area Start--> */}
          <div class="slider-area position-relative home-slider">
            <div class="slider-active">
              {/* <!-- Single Slider --> */}
              <div class="single-slider slider-height d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-7 col-lg-9 col-md-8 col-sm-9">
                      <div class="hero__caption">
                        <h1 style={{ color: "#140c40", fontSize: "60px" }}>
                          <span>Archrom Organics LLP</span>
                        </h1>
                        <h2
                          style={{ color: "#140c40" }}
                          data-animation="fadeInLeft"
                          data-delay="0.4s"
                        >
                          Discover the world of one of the best dyestuff
                          manufacturers.
                        </h2>
                        <br />
                        <Link
                          to="/about"
                          class="btncustom hero-btn"
                          data-animation="fadeInLeft"
                          data-delay="0.7s"
                        >
                          Learn More <i class="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- slider Area End--> */}
          {/* <!--? Categories Area Start --> */}
          <div class="categories-area home-body-intro gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-10">
                  <div class="section-tittle mb-100">
                    {/* <!-- <span class="element">Features</span> --> */}
                    <h2 class="text-center">
                      "Colors Speaks Louder than Words."
                    </h2>
                    <p  style = {{textAlign: "center"}} >
                     
                      At Archrom, we identify the evolving market needs and work
                      towards achieving a competitive advantage to offer our
                      clients’ an extensive range of products under one roof.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- cat End --> */}
          {/* <!-- cards start --> */}
          <div class="container">
            <div class="row">
              <div class="card-deck section-padding40">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/QualityControl/QualityControlBody1.jpg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Quality Control</h3>
                    <p class="card-text">
                      We believe in building trust and offer quality products.
                      Quality and skill of our team is reflected in their
                      performance and commitment in serving the customer to the
                      best of our capabilities. <br></br>
                      <br></br>
                      To maintain this drive and to set this as a system across
                      the organization, we have adopted ISO 9001:2015 Quality
                      Management System.
                    </p>
                    {/* <Link to="/quality-control" class="all-btn">View More</Link> */}
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/ResearchAndDevelopment/RnDBody1.jpg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Research & Development</h3>
                    <p class="card-text">
                      R&D is an integral part of our company and we continue to
                      invest in our R&D facility to be fully equipped with
                      cutting-edge technology and staffed by qualified team of
                      research chemists, engineers, and technicians representing
                      a diverse range of skills. <br></br>
                      <br></br>
                      Our core focus in R&D is to upgrade production technology
                      and processes to improve efficiencies and to develop new
                      products and applications.
                    </p>
                    {/* <Link to="/research-development" class="all-btn">View More</Link> */}
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card">
                  <img
                    class="card-img-top"
                    src="assets/img/CorporateResponsibility/CorporateResponsibilityBody.jpeg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Corporate Responsibility</h3>
                    <p class="card-text">
                      As part of the chemical industry, we at Archrom Organics
                      LLP have faced difficult social, economic, and
                      environmental circumstances in recent years.
                    </p>
                    <Link to="/corporate-responsibility" class="all-btn">
                      View More
                    </Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
                <div class="card" id ="sus">
                  <img
                    class="card-img-top"
                    src="assets/img/Sustainability/Sustainability02.jpg"
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h3 class="card-title">Sustainability</h3>
                    <p class="card-text">
                    Archrom Organics LLP values the need to achieve societal objectives, especially those related to sustainable development, environmental sustainability, social justice, equality. We implement efforts to build an organization that promotes long-term success through accountability and continuous employee growth.
                    </p>
                    <Link to="/sustainability" class="all-btn">
                      View More
                    </Link>
                    {/* <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- cards end --> */}
          {/* <!-- News & Events --> */}
          {/* <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                <div class="section-tittle text-center mb-15">
                  <span class="element">News and Events</span>
                </div>
              </div>
            </div>
          </div>
          <section class="home-blog-area pb-bottom">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="section-tittle mb-15">
                    <span class="element">From News</span>
                    <h2>News and Media center</h2>
                    <p>
                      We collect and analyze information about your general usage of
                      the website products services and courses.
                </p>
                    <Link to="/news" class="all-btn">View All</Link>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="home-blog-single mb-15">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog1.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p>22 Apr 2020</p>
                        <h3>
                          <a href="blog_details.html">
                            We might track your usage patterns to see
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="home-blog-single mb-15">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog2.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p>22 Apr 2020</p>
                        <h3>
                          <a href="blog_details.html"
                          >The massive stadium screens at the MCG</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- News & Events end --> */}
        </main>

        {/* <!-- Scroll Up --> */}
        <div id="back-top">
          <a title="Go to Top" href="#">
            {" "}
            <i class="fas fa-level-up-alt"></i>
          </a>
        </div>
      </div>
    );
  }
}
export default Home;
