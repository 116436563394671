import React from 'react';
// import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './ResearchAndDevelopment.css';
import { Link } from 'react-router-dom';
class ResearchAndDevelopment extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            className="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/ResearchAndDevelopment/RnDSlider.jpeg)" }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Research and Development</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Research and Development Start --> */}

          <section className="blog_area section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      <div className="blog_item_img">
                        <img className="card-img rounded-0" src="assets/img/ResearchAndDevelopment/RnDBody1.jpg" alt="" />
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Research & Development</h2>
                        </div>
                        <p>
                        {/* Archrom Organics LLP puts a focus on research, growth, and R & D laboratories are
                        fully equipped with cutting-edge technology and staffed by a highly skilled team of

                        research chemists, engineers, and technicians representing a diverse range of
                        items. The R & D department is an integral part of the Archrom Organics LLP,
                        serving as an internal centre of excellence for the company and delivering products. */}
                        R&D is an integral part of our company and we continue to invest in our R&D facility to be fully equipped with cutting-edge technology and staffed by qualified team of research chemists, engineers, and technicians representing a diverse range of skills. 
Our core focus in R&D is to upgrade production technology and processes to improve efficiencies and to develop new products and applications.

                        </p>
                        
                         <b> The significant areas of interest are as follows: </b><br/><br/>
                       
                      <p>  Upgrading production technology and processes to improve efficiency.
                        
                        
                          To provide the best service, identify the needs of the customers.
                       
                          We are developing new products and procedures.
                       
                          We are using new technologies to reduce production costs.
                      
                          We are maintaining standardization by updating the laboratory and development with low-cost technology.
                       
                          Maintain a high standard of maintenance.
                          </p>

                        
                      </div>
                    </article>
                  </div>
                </div>
                {/* <SideNavigationACQRG /> */}
              </div>
              <br/>
            <Link
                          to="/"
                          class="btn hero-btn"
                          data-animation="fadeInLeft"
                          data-delay="0.7s"
                        >Back</Link>
            </div>
            
          </section>
          {/* <!-- Research and Development End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}
export default ResearchAndDevelopment;