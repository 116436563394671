import React from 'react';
import SideNavigationTDC from '../SideNavigationTDC/SideNavigationTDC';
import './TextileAuxiliaries.css';

class TextileAuxiliaries extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Textile Auxiliaries</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- About Us Start --> */}
          <div class="categories-area">
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <p>Page under development</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div >
    )
  }
}
export default TextileAuxiliaries;