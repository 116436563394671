import React from 'react';
import { Link } from 'react-router-dom';
import './SideNavigationTDC.css';

class SideNavigationTDC extends React.Component {
  render() {
    return (
      <>
        <div class="col-xl-4 col-lg-4">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title" style={{ color: '#2d2d2d' }}>In This Section</h4>
              <ul class="list cat-list active">
                <li>
                  <Link to="/acid-dyes" class="d-flex">
                    <p>Acid Dyes</p>
                  </Link>
                </li>
                <li>
                  <Link to="/basic-dyes" class="d-flex">
                    <p>Basic Dyes</p>
                  </Link>
                </li>
                <li>
                  <Link to="/direct-dyes" class="d-flex">
                    <p>Direct Dyes</p>
                  </Link>
                </li>
                <li>
                  <Link to="/lanasol-dyes" class="d-flex">
                    <p>Lanasol Dyes</p>
                  </Link>
                </li>
                <li>
                  <Link to="/reactive-dyes" class="d-flex">
                    <p>Reactive Dyes</p>
                  </Link>
                </li>
                <li>
                  <Link to="/salt-free-dyes" class="d-flex">
                    <p>Salt Free Dyes</p>
                  </Link>
                </li>{/* 
                <li>
                  <Link to="/textile-auxiliaries" class="d-flex">
                    <p>Textile Auxiliaries</p>
                  </Link>
                </li>
                <li>
                  <Link to="/digital-textile-printing" class="d-flex">
                    <p>Digital Textile Printing</p>
                  </Link>
                </li>
                <li>
                  <Link to="/construction-chemicals" class="d-flex">
                    <p>Construction Chemicals</p>
                  </Link>
                </li> */}
              </ul>
            </aside>
          </div>
        </div>
      </>
    )
  }
}

export default SideNavigationTDC;