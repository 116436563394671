import React from 'react';
import InquiryData from '../InquiryData/InquiryData';
import './Inquiry.css';
import { GiFactory } from "react-icons/gi";
import { ImOffice } from "react-icons/im";
import { FaIndustry } from "react-icons/fa";
import { RiQuestionAnswerFill } from "react-icons/ri";
const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


class Inquiry extends React.Component {



  render() {
    const mapStyle = {
      overflow: "hidden",
      paddingBottom: "45%",
      position: "relative",
      height: "0",
    }
    const iframeMapStyle = {
      left: "0",
      top: "0",
      height: "100%",
      width: "100%",
      position: "absolute"
    }
    return (

      <div>

        <main>
          {/* <!--? Hero Start --> */}
          {/* <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/Inquiry/InquirySlider.jpg)" }}> */}
          <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/About/ContactUs.jpg)" }}>
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2 text-left">
                      <h2>Contact Us</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!--?  Contact Area start  --> */}
          <section  style = {{paddingTop : 80}}>
            <div class="container">
              <div class="row" style={{display: 'flex', justifyContent: 'center'}}>
                {/* <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12"> */}
                <div>
                  <div style={mapStyle}>
                    <iframe style={iframeMapStyle} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7409.707639779888!2d72.80947807647173!3d21.785917854942777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f871ebe015555%3A0x63632f16b296dcdc!2sArchrom%20Organics%20LLP!5e0!3m2!1sen!2sus!4v1655217991649!5m2!1sen!2sus" width="100%" height="500"  allowfullscreen="" loading="lazy"></iframe>
                  </div>
                </div>

                {/* <div class="col-lg-3 offset-lg-1"> */}
                <div class = "container row d-flex justify-content-between" style = {{paddingTop : 40}}>
                  <div class="col-md-3 media contact-info">
                    <span class="contact-info__icon"><h1><ImOffice/></h1></span>
                    <div class="media-body">
                      <h3>Corporate & Registered Office</h3>
                      <p>
                      607-612, Shilp Aperia, 
                      Nr.Hotel Planet Landmark, Iscon-Ambali Road,
                      Ahmedabad - 380058, Gujarat
                      </p>
                    </div>
                  </div>

                  <div class="col-md-3 media contact-info">
                  <span class="contact-info__icon"><h1><FaIndustry/></h1></span>
                    <div class="media-body">
                      <h3>Unit-1</h3>
                      <p>
                      Plot No.T -71,72, GIDC Saykha,
                      Taluka. Vagra, Nr. CEPT Plant, Dist. Bharuch, Gujarat-392140,
                      India
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3 media contact-info">
                  <span class="contact-info__icon"><h1><GiFactory/></h1></span>
                    <div class="media-body">
                      <h3>Unit-2</h3>
                      <p>
                      Plot No.1315, Phase-lll, G.I.D.C.,
                      Behind Vijay Transport, Vatva,
                      Laxminarayan Weigh Bridge Lane,
                      Ahmedabad - 382445. (India)
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3 media contact-info">
                  <span class="contact-info__icon"><h1><RiQuestionAnswerFill/></h1></span>
                    <div class="media-body">
                      <h3>sales@archrom.com</h3>
                      <p>Send us your query anytime!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-40" >
                <div class="col-12">
                  <h2 style={{ textAlign: 'center' }} class="contact-title">How May We Help You?</h2>
                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-12" style={{display: 'flex', justifyContent: 'center'}}>
                  <InquiryData />
                </div>
                <div class="col-lg-2"></div>
              </div>
            </div>
          </section>
          {/* <!-- Contact Area End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>

      </div >
    )
  }
}

export default Inquiry;