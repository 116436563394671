import React from 'react';
import SideBarEventsCategories from '../SideBarEventsCategories/SideBarEventsCategories';
import SideBarEventsRecentPosts from '../SideBarEventsRecentPosts/SideBarEventsRecentPosts';
import SideBarEventsTags from '../SideBarEventsTags/SideBarEventsTags';
import './SideBarEvents.css';

class SideBarEvents extends React.Component {
  render() {
    return (
      <>
        <SideBarEventsCategories />
        <SideBarEventsRecentPosts />
        <SideBarEventsTags />
      </>
    )
  }
}

export default SideBarEvents;