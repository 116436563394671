import React from 'react';
import './Career.css';
import Popup from 'reactjs-popup';
class Career extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/Career/CareerBody1.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Careers</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Career Start --> */}

          <section className="blog_area " style = {{paddingTop : 30}}>
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                    
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Careers at Archrom Organics LLP</h2>
                        </div>
                        <p>
                          Strategy can guide, and technology can innovate, but people are the ones that propel a company ahead at the end of the day.  <br /><br />
                          Employees now look forward to being a part of this organization, and we are known for giving people the chance to take on challenging duties. We have a reputation that few can match in terms of exposure, working conditions, and salary. <br /><br />
                         <b> Note:  Archrom Organics LLP does not seek Fee/Cash deposit for any job Offer/ Interview. Please report any such call/email to admin@archrom.com </b>
                        </p>
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>
                            Current Opportunities
                          </h2>
                        </div>
                        <p>
                          
                        <div >
                       <h3> 1) Global Marketing Manager </h3>
                        <h3> 2) Production Chemist </h3>
                        <h3> 3) Marketing Executive </h3>
                        <h3> 4) Lab Chemist </h3>
                        <h3> 5) Logistics Officer </h3>
                        <h3> 6) Plant Manager </h3>
                        <h3> 7) Safety Officer </h3>
                        <h3> 7) Project InCharge Officer </h3>
                        <h3> 7) House Keeping </h3>
            
                        
                        <br></br>
                        <h3><b>- Contact us on admin@archrom.com for more information.</b> </h3>
                          
                        </div>

                        </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Career End --> */}


      </div>

      
    )
  }
}

export default Career;