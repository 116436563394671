import React from 'react';
import './SideBarNewsRecentPosts.css';

class SideBarNewsRecentPosts extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget popular_post_widget">
          <h3 class="widget_title" style={{ color: '#2d2d2d' }}>Recent Post</h3>
          <div class="media post_item">
            <img src="assets/img/post/post_1.png" alt="post" />
            <div class="media-body">
              <a href="blog_details.html">
                <h3 style={{ color: '#2d2d2d' }}>From life was you fish...</h3>
              </a>
              <p>January 12, 2019</p>
            </div>
          </div>
          <div class="media post_item">
            <img src="assets/img/post/post_2.png" alt="post" />
            <div class="media-body">
              <a href="blog_details.html">
                <h3 style={{ color: '#2d2d2d' }}>The Amazing Hubble</h3>
              </a>
              <p>02 Hours ago</p>
            </div>
          </div>
          <div class="media post_item">
            <img src="assets/img/post/post_3.png" alt="post" />
            <div class="media-body">
              <a href="blog_details.html">
                <h3 style={{ color: '#2d2d2d' }}>Astronomy Or Astrology</h3>
              </a>
              <p>03 Hours ago</p>
            </div>
          </div>
          <div class="media post_item">
            <img src="assets/img/post/post_4.png" alt="post" />
            <div class="media-body">
              <a href="blog_details.html">
                <h3 style={{ color: '#2d2d2d' }}>Asteroids telescope</h3>
              </a>
              <p>01 Hours ago</p>
            </div>
          </div>
        </aside>

      </>
    )
  }
}

export default SideBarNewsRecentPosts;