import React, { Component } from 'react'
import './DirectDyes.css';

class DirectDyes extends React.Component {
  state = {
    showCommercializeProducts: false,
    showPilotBasisProducts: false
  }

  toggleCommercializedProductsHandler = () => {
    const doShowCommercializeProducts = this.state.showCommercializeProducts;
    this.setState({ showCommercializeProducts: !doShowCommercializeProducts });
  }

  togglePilotBasisProductsHandler = () => {
    const doShowPilotBasisProducts = this.state.showPilotBasisProducts;
    this.setState({ showPilotBasisProducts: !doShowPilotBasisProducts });
  }

  render() {

    let commercializeProducts = null;
    let pilotBasisProducts = null;

    if (this.state.showCommercializeProducts) {
      commercializeProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Commercialized Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Direct Red 9</h3>
            </li>
            <li>
              <h3>Direct Blue 290</h3>
            </li>
            <li>
              <h3>Direct Blue 71</h3>
            </li>
            <li>
              <h3>Direct Black 179</h3>
            </li>
            <li>
              <h3>Direct Red 227</h3>
            </li>
            <li>
              <h3>Direct Blue 94</h3>
            </li>
            <li>
              <h3>Direct Red 81</h3>
            </li>
            <li>
              <h3>Direct Red 239</h3>
            </li>
            <li>
              <h3>Direct Black 80</h3>
            </li>
            <li>
              <h3>Direct Blue 281</h3>
            </li>
            <li>
              <h3>Direct Blue 85</h3>
            </li>
            <li>
              <h3>Direct Black 19</h3>
            </li>
            <li>
              <h3>Direct Black 168</h3>
            </li>
          </ul>
        </>
      )
    }

    if (this.state.showPilotBasisProducts) {
      pilotBasisProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Pilot Based Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Direct Red 831.1</h3>
            </li>
            <li>
              <h3>Direct Blue 160</h3>
            </li>
            <li>
              <h3>Direct Blue 273</h3>
            </li>
            <li>
              <h3>Direct Blue 78</h3>
            </li>
            <li>
              <h3>Direct Red 224</h3>
            </li>
            <li>
              <h3>Direct Black 62</h3>
            </li>
            <li>
              <h3>Direct Red 23</h3>
            </li>
            <li>
              <h3>Direct Red 254</h3>
            </li>
            <li>
              <h3>Direct Red 83</h3>
            </li>
            <li>
              <h3>Direct Red 89</h3>
            </li>
            <li>
              <h3>Direct Blue 301</h3>
            </li>
            <li>
              <h3>Direct Blue 67</h3>
            </li>
            <li>
              <h3>Direct Red 111</h3>
            </li>
            <li>
              <h3>Direct Blue 80</h3>
            </li>
            <li>
              <h3>Direct Blue 293</h3>
            </li>
            <li>
              <h3>Direct Black 56</h3>
            </li>
            <li>
              <h3>Direct Blue 200</h3>
            </li>
            <li>
              <h3>Direct Blue 201</h3>
            </li>
            <li>
              <h3>Direct Green 26</h3>
            </li>
          </ul>
        </>
      )
    }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/DirectDyesSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Direct Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>DIRECT DYES</h2>
                    <p>
                      Direct dyes that are water-soluble and ionizable to form colored anions can be used to dye cellulose or protein fibers without using a mordant.
                      The majority of the direct dyes are sodium salts of aromatic compounds.
                      They are water-soluble, and their solubility increases with increasing temperature.
                      In water, direct dyes can dissociate into anions.
                      The leather surface can be easily dyed, but direct dyes have a high molecular weight and low permeability, making it difficult to penetrate the leather interior.
                      So, before dyeing, we usually add a little ammonia to help the dye penetrate and slow down the leather's reaction.
                    </p>
                    <p>
                      Pick your product in the table below, then request a product from the applicable range of Direct Dyes to get specifics and a downloadable brochure for your particular requirement.
                      Information on the recommended coloration method is also given in the booklet, so you have all the knowledge you need to meet your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleCommercializedProductsHandler} class="btn">
                                {
                                  !this.state.showCommercializeProducts ?
                                    <>Show Commercialized Products</> :
                                    <>Hide Commercialized products</>
                                }
                              </button>
                              {commercializeProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

              <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.togglePilotBasisProductsHandler} class="btn">
                                {
                                  !this.state.showPilotBasisProducts ?
                                    <>Show Pilot Based Products</> :
                                    <>Hide Pilot Based Products</>
                                }
                              </button>
                              {pilotBasisProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default DirectDyes;