import React from 'react';
import './DisperseDyes.css';


class DisperseDyes extends React.Component {

  state = {
    showCommercializeProducts: false,
    showPilotBasisProducts: false
  }

  toggleCommercializedProductsHandler = () => {
    const doShowCommercializeProducts = this.state.showCommercializeProducts;
    this.setState({ showCommercializeProducts: !doShowCommercializeProducts });
  }

  togglePilotBasisProductsHandler = () => {
    const doShowPilotBasisProducts = this.state.showPilotBasisProducts;
    this.setState({ showPilotBasisProducts: !doShowPilotBasisProducts });
  }

  render() {

    let commercializeProducts = null;
    let pilotBasisProducts = null;

    if (this.state.showCommercializeProducts) {
      commercializeProducts = (
        <>
          <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Commercialized Products </h1>
          <ul class="list cat-list" >
            <li>
              <h3>Yellow 114:2
              </h3>
            </li>
              <li>
              <h3>Yellow 114:1
              </h3>
            </li>
              <li>
              <h3>Yellow 114
              </h3>
            </li>
              <li>
              <h3>Yellow 119
              </h3>
            </li>
              <li>
              <h3>Yellow 79
              </h3>
            </li>
              <li>
              <h3>Yellow 184:1
              </h3>
            </li> 
              <li>
              <h3>Yellow 211
              </h3>
            </li>
<li>
              <h3>Yellow 229
              </h3>
            </li>
              <li>
              <h3>Orange 25
              </h3>
            </li>
              <li>
              <h3>Orange 44
              </h3>
            </li> 
              <li>
              <h3>Red 13
              </h3>
            </li>
              <li>
              <h3>Red 50
              </h3>
            </li>
              <li>
              <h3>Red 54
              </h3>
            </li>
              <li>
              <h3>Red 74
              </h3>
            </li>
              <li>
              <h3>Red 167:1
              </h3>
            </li>
              <li>
              <h3>Red 343
              </h3>
            </li>
              <li>
              <h3>Red 343:1
              </h3>
            </li>
              <li>
              <h3>Sol.Red 196 
              </h3>
            </li>
              <li>
              <h3>Blue 60:2
              </h3>
            </li>
              <li>
              <h3>Blue 87:1
              </h3>
            </li>
              <li>
              <h3>Blue 56:1
              </h3>
            </li>
 
          </ul>
        </>
      )
    }

    // if (this.state.showPilotBasisProducts) {
    //   pilotBasisProducts = (
    //     <>
    //       <h1 class="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Pilot Based Products </h1>
    //       <ul class="list cat-list" >
    //         <li>
    //           <h3>Reactive Blue RR</h3>
    //         </li>
    //         <li>
    //           <h3>Reactive Blue 109</h3>
    //         </li>
    //         <li>
    //           <h3>Reactive Red 21</h3>
    //         </li>
    //         <li>
    //           <h3>Reactive Red 222</h3>
    //         </li>
    //         <li>
    //           <h3>Reactive Red 58</h3>
    //         </li>
    //         <li>
    //           <h3>Reactive Red 22</h3>
    //         </li>
    //       </ul>
    //     </>
    //   )
    // }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/DisperseDyesSlider.jpg)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Disperse Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div class="categories-area section-padding40 gray-bg">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="section-tittle text-justify">
                    <span class="element">What we offer</span>
                    <h2>Disperse Dyes</h2>
                    <p>
                    The term ‘Disperse dye’ is an organic coloring substance which is free from ionizing group are of low water solubility and are suitable for dyeing hydrophobic textile materials from colloid dispersion. It has low molecular weight and derivatives of Azo, Anthra – quinine, Di phynile amine and other component.
                    </p>
                    <p>
                    Disperse dyes are used for sublimation transfer printing, but can also be printed directly to the cloth. Although disperse dyes are substantive to acrylics, triacetates, and other synthetic fibers, polyesters are the largest fiber group for this printing application.
                    </p>
                    <p>
                      Pick your product in the table below, then request a product from the applicable range of Disperse Dyes to get specifics and a downloadable brochure for your particular requirement.
                      Information on the recommended coloration method is also given in the booklet, so you have all the knowledge you need to meet your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div class="container mt-50 mb-50">
            <div class="row">

              {/* <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div> */}

              {/* <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              {/* <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleCommercializedProductsHandler} class="btn">
                                {
                                  !this.state.showCommercializeProducts ?
                                    <>Show Commercialized Products</> :
                                    <>Hide Commercialized products</>
                                }
                              </button>
                              {commercializeProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div> */}
              {/* </div> */} 

              {/* <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div class="blog_right_sidebar">
                  <aside class="single_sidebar_widget post_category_widget">
                    <ul class="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.togglePilotBasisProductsHandler} class="btn">
                                {
                                  !this.state.showPilotBasisProducts ?
                                    <>Show Pilot Based Products</> :
                                    <>Hide Pilot Based Products</>
                                }
                              </button>
                              {pilotBasisProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div> */}

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default DisperseDyes;