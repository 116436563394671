import React from 'react';
import { Link } from 'react-router-dom';
import './SideNavigationACQRG.css';

class SideNavigationACQRG extends React.Component {
  render() {
    return (
      <>
        <div class="col-xl-4 col-lg-4">
          <div class="blog_right_sidebar">
            <aside class="single_sidebar_widget post_category_widget">
              <h4 class="widget_title" style={{ color: "#2d2d2d;" }}>In This Section</h4>
              <ul class="list cat-list active">
                <li>
                  <Link to="/about" class="d-flex">
                    <p>Archrom Organics LLP</p>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/chairmans-message" class="d-flex">
                    <p>Chairman's Message</p>
                  </Link>
                </li> */}
                <li>
                  <Link to="/quality-control" class="d-flex">
                    <p>Quality Control</p>
                  </Link>
                </li>
                <li>
                  <Link to="/research-development" class="d-flex">
                    <p>Research and Development</p>
                  </Link>
                </li>
                <li>
                  <Link to="/partnership-announcement" class="d-flex">
                    <p>Partnership Annoucement</p>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/group-companies" class="d-flex">
                    <p>Group Companies</p>
                  </Link>
                </li> */}
              </ul>
            </aside>
          </div>
        </div>
      </>
    )
  }
}
export default SideNavigationACQRG