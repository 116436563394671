import React from 'react';
import './SideBarEventsCategories.css';

class SideBarEventsCategories extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget post_category_widget">
          <h4 class="widget_title" style={{ color: '#2d2d2d' }}>Category</h4>
          <ul class="list cat-list">
            <li>
              <a href="#" class="d-flex">
                <p>Resaurant food</p>
                <p>(37)</p>
              </a>
            </li>
            <li>
              <a href="#" class="d-flex">
                <p>Travel news</p>
                <p>(10)</p>
              </a>
            </li>
            <li>
              <a href="#" class="d-flex">
                <p>Modern technology</p>
                <p>(03)</p>
              </a>
            </li>
            <li>
              <a href="#" class="d-flex">
                <p>Product</p>
                <p>(11)</p>
              </a>
            </li>
            <li>
              <a href="#" class="d-flex">
                <p>Inspiration</p>
                <p>21</p>
              </a>
            </li>
            <li>
              <a href="#" class="d-flex">
                <p>Health Care (21)</p>
                <p>09</p>
              </a>
            </li>
          </ul>
        </aside>

      </>
    )
  }
}

export default SideBarEventsCategories;