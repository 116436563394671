import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

export default class Header extends React.Component {
  render() {
    return (
      <div>
        {/* <!-- ? Preloader Start --> */}
        <div id="preloader-active">
          <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
              <div className="preloader-circle"></div>
              <div className="preloader-img pere-text">
                <img src="assets/img/logo/ARCHROM.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Preloader Start --> */}
        <header>
          <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid d-flex ">
              <Link className="navbar-brand brand-logo" to="/">
                <img className="logo-image" src="assets/img/logo/ARCHROM.png" />
              </Link>
              <button className="navbar-toggler nav-toogle-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">

                <ul className="navbar-nav nav-main-menu nav-ul-padding me-auto mb-2 mb-lg-0">
                  <li className="nav-item nav-li-padding dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      About Us
                    </a>
                    <ul className="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown">
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/about">Company Profile</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/Management">Management </Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/association">Association </Link></li>
                    </ul>
                  </li>
                  <li className="nav-item nav-li-padding dropdown">
                  <Link  className="nav-link"  to="/solutions">Products</Link>
                    {/* <ul className="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown"> */}
                      {/* <li className="nav-dropdown-li"><Link className="dropdown-item" to="/solutions">What We Offer</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/acid-dyes">Acid Dyes</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/basic-dyes">Basic Dyes</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/disperse-dyes">Disperse Dyes</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/">Solvent Dyes</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/">Vat Dyes</Link></li> */}
                      {/* <li className="nav-dropdown-li"><Link className="dropdown-item" to="/basic-dyes">Basic Dyes</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/direct-dyes">Direct Dyes</Link></li> */}
                      {/* <li className="nav-dropdown-li"><Link className="dropdown-item" to="/lanasol-dyes">Lanasol Dyes</Link></li> */}
                      {/* <li className="nav-dropdown-li"><Link className="dropdown-item" to="/reactive-dyes">Reactive Dyes</Link></li> */}
                      {/* <li className="nav-dropdown-li"><Link className="dropdown-item" to="/salt-free-dyes">Salt Free Dyes</Link></li> */}
                    {/* </ul> */}
                  </li>
                  {/* <li className="nav-item nav-li-padding dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Sustainability
                    </a>
                    <ul className="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown">
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/sustainability">Sustainability</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/corporate-responsibility">Corporate Responsibility</Link></li>
                    </ul>
                  </li> */}

                  {/* <li className="nav-item nav-li-padding dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Sales Network
                    </a>
                    <ul className="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown">
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/sales-network">Overview</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/international-sales-network">International Sales Network</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/india-sales-network">India Sales Network</Link></li>
                    </ul>
                  </li> */}
                  <li className="nav-item nav-li-padding dropdown">
                  <Link  className="nav-link"  to="/certification">Certification</Link>
                  </li> 
                  <li className="nav-item nav-li-padding dropdown">
                  <Link  className="nav-link"  to="/gallery">Gallery</Link>
                  </li> 
                  <li className="nav-item nav-li-padding dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Inquiry
                    </a>
                    <ul className="dropdown-menu nav-dropdown-menu" aria-labelledby="navbarDropdown">
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/inquiry">Contact Us</Link></li>
                      <li className="nav-dropdown-li"><Link className="dropdown-item" to="/career">Careers</Link></li>
                    </ul>
                  </li>
                </ul>
              </div>

            </div>


          </nav>

        </header>

      </div>
    )
  }
}