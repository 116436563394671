import React from 'react';
import './FooterCopyRight.css';

export default class FooterCopyRight extends React.Component {
  render() {
    return (
      <>

        <div className="footer-bottom-area">
          <div class="container">
            <div class="footer-border">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div class="footer-copy-right text-center">
                    {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}

                    <p>
                     Copyright © 2024 Archrom Organics LLP. All Rights Reserved.
                    {/* | Template by
                      <a href="https://colorlib.com/" target="_blank" rel="noopener noreferrer"> Colorlib </a> & Developed by
                      <a href="" target="_blank" rel="noopener noreferrer"> Smit Shiroya</a> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}
