import React from 'react';
import NewsArticles from '../NewsArticles/NewsArticles';
import NewsPageNavigation from '../NewsPageNavigation/NewsPageNavigation';
import SideBarNews from '../SideBarNews/SideBarNews';
import './News.css';

class News extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/hero/NewsSlider.jpg)" }}>
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2 text-center">
                      {/* <h2>News</h2> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!--? News Area Start--> */}
          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <NewsArticles />
                    <NewsPageNavigation />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog_right_sidebar">
                    <SideBarNews />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- News Area End --> */}
        </main>

      </div >
    )
  }
}

export default News;