import React from 'react';
// import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './Association.css';

class Association extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            className="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/PartnershipAnnouncement/PartnershipAnnouncement.jpg)" }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Association</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Research and Development Start --> */}

          <section style={{paddingTop:30}}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">

                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Association</h2>
                        </div>
                        <p>
                        {/* We are pleased to announce a new partnership between <b>Archrom Organics LLP & A.K.Industries</b>. 
                        A.K.Industries manufactures a wide range of Reactive dyes sold in Europe and Far East Countries. 
                        A.K.Industries is a manufacturer of the full range of Reactive Dyes.
                        And as you may be aware of, we are one of the leading manufacturers & exporters of Dyestuff with fully automized spray drying & packing system & advanced Laboratory..
                        We are thinking to expand the services by working in close partnership. Our principal is to satisfy the customer by providing our best services.  */}
                       Our associate company, M/s. A.K.Industries, manufactures a wide range of Reactive, Acid and Direct dyes since past 22 years and is already an established player in the Dyes & Intermediate industry. 
                       With their products widely accepted in domestic and global markets and their existing customer relations, 
                       this association will immensely benefit Archrom Organics to penetrate the markets and expand services across the globe
                        </p>
                        {/* <p>
                        This Association will surely help us enhance and enrich our business and also
                        provide new services.
                       </p> */}
                        <p>Website : <a href="http://akindustries.in">www.akindustries.in</a> </p>
                        <img className="card-img rounded-0" src="assets/img/PartnershipAnnouncement/PartnerLogo.png" alt="" />
                      </div>
                    </article>
                  </div>
                </div>
                {/* <SideNavigationACQRG /> */}
              </div>
            </div>
          </section>
          {/* <!-- Research and Development End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}
export default Association;