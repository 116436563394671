import React from 'react';
import { renderIntoDocument } from 'react-dom/test-utils';
import './SideBarNewsLetter.css';

class SideBarNewsLetter extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget newsletter_widget">
          <h4 class="widget_title" style={{ color: '#2d2d2d' }}>Newsletter</h4>
          <form action="#">
            <div class="form-group">
              <input type="email" class="form-control" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Enter email'" placeholder='Enter email' required />
            </div>
            <button class="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
              type="submit">Subscribe</button>
          </form>
        </aside>

      </>
    )
  }

}

export default SideBarNewsLetter;