import React from 'react';
// import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './About.css';

class About extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? Hero Start --> */}
          <div className=" about-slider slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/About/AboutUsSlider03.jpg)" }}>
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Company Profile</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- About Us Start --> */}

         
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      {/* <div className="blog_item_img">
                        <img className="card-img rounded-0" src="assets/img/About/AboutBody.jpg" alt="" />
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d" }}>Vision</h2>
                        </div>
                        <p>Our proactive customer service, excellence in quality and unparalleled economical products will make us the best and the biggest reactive dyestuff producer in the world. We are totally committed to upgrading social, economic and environmental conditions of mankind.</p>
                      </div> */}
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Your Partner Of Choice</h2>
                        </div>
                        <p>
                        {/* Archrom Organics LLP was established in the year 2018 for a greenfield project of manufacturing Dyes and Intermediates in Saykha G.I.D.C. near Dahej, Gujarat. <br/><br/>

Promoted by a team of Businessman and Technocrats with an experience of over past two decades in their respective domains of Finance, 
Sales & Marketing and Production, the Company was set up with a vision to identify evolving market needs in the segment of Dyes & Intermediates and work towards achieving a competitive advantage, to offer its clients’ an extensive range of products under one roof, 
with a major focus to offer the most innovative high-performance specialty products.<br/><br/>

With Phase 1 Plant for Formulation, Sand-milling and Spray Drying already installed & commissioned in the year 2021 and currently under commercial production, 
work for development of Synthesis Plant in Phase 2 has commenced and is expected to be commissioned by April 2023. 
This will facilitate the forward and backward integration for a complete in-house manufacturing of wide range of products which will help cater to the needs of various industry segments.<br/><br/> */}
With a greenfield project for manufacturing Dyes and intermediates,
Archrom Organics LLP was established in the year 2018 by a team of businessmen and technocrats with an experience of over two decades in their respective domains. The company was set up with a vision to cater to the evolving market needs for Dyes and Intermediates, with a special focus to innovate and manufacture high performance specialty products.
<br/><br/>First phase of our infrastructure was commissioned in the year 2021 with processing facilities for Formulation, High speed Sand-milling and Spray Drying. Work for expansion of a Synthesis Plant is under process and the plant is expected to be fully operational by June
2024. This plant will primarily facilitate backward as well as forward integration for a complete in-house manufacturing of a wide range of products and help cater to the needs of various industry segments.
                         
                                   </p>
                      </div>
                    </article>
                  </div>
                </div>
                {/* <SideNavigationACQRG /> */}
              </div>
            </div>
         
          {/* <!-- About Us End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div>

    )
  }
}
export default About