import React from 'react';
import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './GroupCompanies.css';

class GroupCompanies extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Group Companies</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Group Companies start --> */}
          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6  home-blog-area">
                  <div class="home-blog-single mb-30">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog1.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p></p>
                        <h3><a href="blog_details.html">JAY INFA TRADE</a></h3>
                        <p>
                          Jay Infa Trade Pvt. Ltd., a group company of Jay Chemicals, is a ready-made Jeans facility in Ahmedabad, India with an annual capacity of a quarter million pieces. The manufacture of ready-made garments has emerged as an interesting growth area for Jay Chemicals. <br /><br />
                          Our key customers include large Indian chains like Arvind Brands, Peter England, Reliance Retail, ITC Wills Lifestyle, among several others. Apart from the Indian market, we have established business relations with major companies in Europe and US. <br /><br />
                          The facility is equipped with a high level of automation like loop attach machines, back pocket design machines, etc., to ensure a high level of production efficiency. We have capability in specialty finishes like hand sanding, sand blasting, whiskers with laser and hand, garment tinting, spraying, brushing, grinding, and permanent creasing and coating.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6  home-blog-area">
                  <div class="home-blog-single mb-30">
                    <div class="blog-img-cap">
                      <div class="blog-img">
                        <img src="assets/img/gallery/home-blog2.png" alt="" />
                      </div>
                      <div class="blog-cap">
                        <p></p>
                        <h3><a href="blog_details.html">LIFE IS PRECIOUS</a></h3>
                        <p>
                          JAY LifeCare Pvt. Ltd. is a healthcare venture of the group. JAY LifeCare offers a diversified range of Pharmaceutical products, covering several therapeutic segments. JAY LifeCare's objective is to reach out to customers at various levels with a unique caring philosophy. <br /><br />
                          JAY LifeCare is a pharmaceutical company promoted by JAY Chemical Industries Ltd., Currently, JAY LifeCare markets a wide-range of prescription generic products across multiple states in India. JAY LifeCare is executing an aggressive growth plan pan-India and is actively exploring exports. <br /><br />
                          For details please visit: www.jaylifecare.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <SideNavigationACQRG />
              </div>
            </div>
          </section>
          {/* <!-- Group Companies end --> */}
        </main>
      </div>
    )
  }
}

export default GroupCompanies;