import React from 'react';
import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import './QualityControl.css';
import { Link } from 'react-router-dom';
class QualityControl extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            className="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/QualityControl/QuialityControlSlider.jpg)" }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Quality Control</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Quality Control Start --> */}

          <section className="blog_area section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      <div className="blog_item_img">
                        <img className="card-img rounded-0" src="assets/img/QualityControl/QualityControlBody.jpg" alt="" />
                      </div>
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2 className="blog-head" style={{ color: "#2d2d2d;" }}>Quality Control</h2>
                        </div>
                        <p>
                           {/* "Archrom Organics LLP" the name is known for trust and quality of service across
                        the globe. We discover quality issues through our ISO 9001 / ISO 140001 registration and take corrective action to stop them from happening again.<br /><br />

                        We believe in enhancing the quality of our team, which is ultimately shown
                        in their performance and commitment in serving the customer at our best. 

                        
                        To maintain this drive and to set this as a system across the organization, we have adopted
                        Quality Management System. 

                        We have state of the art laboratory facilities, which product-specific technical professionals
                        manage, thereby conforming to the third-party certification of the product before delivery
                        other than our factory laboratory.  */}
                        We believe in building trust and offer quality products. Quality and skill of our team is reflected in their performance and commitment in serving the customer to the best of our capabilities.
To maintain this drive and to set this as a system across the organization, we have adopted ISO 9001:2015 Quality Management System.


                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                {/* <SideNavigationACQRG /> */}

              </div>
              <Link
                          to="/"
                          class="btn hero-btn"
                          data-animation="fadeInLeft"
                          data-delay="0.7s"
                        >Back</Link>
            </div>
            <br/>
           
          </section>
          {/* <!-- Quality Control End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}
export default QualityControl