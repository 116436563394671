import React from 'react';
import SideNavigationIIP from '../SideNavigationIIP/SideNavigationIIP';
import './PartnerProgram.css';

class PartnerProgram extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Partner Program</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Partner Program Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/partner-program-banner.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>PARTNER PROGRAM</h2>
                        </div>
                        <p>
                          At Jay Chemical Industries Ltd we have a long, strong and mutually beneficial, business relationships with our agents in many parts of the globe, where we market and sell our well established brand names of Jakazol and Jakofix reactive dyes. <br /><br />
                          Any successful Jay agent must be able to provide local warehousing, stock-holding and control, logistics, debt collection, and techno-commercial support to all potential customers. <br /><br />
                          Our agents in all markets are in fact the ambassador of the Jay brand in that market, and we demand a transparent and open business relationship. We are constantly developing new markets to extend our coverage of the extensive global textile producing marketplace. <br /><br />
                          We would like to invite any potential agent of good repute, in a market where there is room for significant growth to approach JAY Chemical Industries Private Limited through this website in order to start a discussion. <br /><br />
                        </p>
                        <h3>
                          To Partner Jay, contact <br />
                          advaitpurani@jaychemical.com for Indian market. <br />
                          dakshesh@jaychemical.com for international market.
                        </h3>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationIIP />
              </div>
            </div>
          </section>
          <div id="back-top">
            <a title="Go to Top" href="#"> <i class="fas fa-level-up-alt"></i></a>
          </div>
        </main>
        {/* <!-- Partner Program End --> */}

      </div>
    )
  }
}

export default PartnerProgram;