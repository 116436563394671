import React from 'react';
import EventArticles from '../EventArticles/EventArticles';
import EventsPageNavigation from '../EventsPageNavigation/EventsPageNavigation';
import SideBarEvents from '../SideBarEvents/SideBarEvents';
import './Events.css';

class Events extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div class="slider-area2 section-bg2" style={{ backgroundImage: "url(/assets/img/hero/EventsSlider.jpg)" }}>
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2 text-center">
                      <h2>Events</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  <!-- Hero End --> */}
          {/* <!--? Events Area Start--> */}
          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <EventArticles />
                    <EventsPageNavigation />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="blog_right_sidebar">
                    <SideBarEvents />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Events Area End --> */}
        </main>

      </div>
    )
  }
}

export default Events;