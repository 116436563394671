import React from 'react';
import SideNavigationTDC from '../SideNavigationTDC/SideNavigationTDC';
import './ConstructionChemicals.css';

class ConstructionChemicals extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Construction Chemicals</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Construction Chemicals Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_item_img">
                        <img class="card-img rounded-0" src="assets/img/about-us-img.jpg" alt="" />
                      </div>
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d' }}>CONSTRUCTION CHEMICALS</h2>
                        </div>
                        <p>
                          Looking for new opportunities and using four decades of experience in manufacturing and marketing, JCIL launched its Construction Chemicals Division under the brand name K2. <br /><br />
                          Aptly tagged as 'Restructuring Konstruction. Kemically', JCIL's K2 is poised to bring its expertise to different construction chemicals ranging from water-proofing chemicals, grouts, plasticizer, tile adhesives, jointfillers and epoxy bonding-agents to name a few. <br /><br />
                          K2 products are manufactured at JCIL's plant at Sanand, Gujarat using an array of advanced equipments and systems. The quality benchmark is extremely high as R&D looks for continuous improvement and stringent quality control is employed. K2's superior quality production is finally a result of infrastructure, technology and technically skilled professionals. <br /><br />
                          K2 takes pride in serving a select group of elite professionals and projects in the construction industry. The company aims to grow rapidly through technical collaborations with global leaders, M&A, investments in R&D, training and exports. <br /><br />
                          A separate, dedicated and well equipped division ensures easy access and quick response to meet the demands of the construction industry with a team of experienced and mature marketing professionals. K2 has launched its products through distributors, retailers, applicators and projects in the major states of India. The company plans to cover the whole of the subcontinent and SAARC region as a part of its future expansion. <br /><br />
                          In order to improve industry standards, K2 has taken a strong initiative, as an ongoing commitment, to educate the public and professionals regarding its products. The focus areas being; - advances in construction chemicals, proper methodology of product application as well as creating a general awareness regarding the environment and environmental impacts.

                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationTDC />
              </div>
            </div>
          </section>
        </main>
        {/* <!-- Construction Chemicals End --> */}

      </div>
    )
  }
}

export default ConstructionChemicals;