import React from 'react';
import './SideBarNewsInstaFeeds.css';

class SideBarNewsInstaFeeds extends React.Component {
  render() {
    return (
      <>

        <aside class="single_sidebar_widget instagram_feeds">
          <h4 class="widget_title" style={{ color: '#2d2d2d' }}>Instagram Feeds</h4>
          <ul class="instagram_row flex-wrap">
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_5.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_6.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_7.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_8.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_9.png" alt="" />
              </a>
            </li>
            <li>
              <a href="#">
                <img class="img-fluid" src="assets/img/post/post_10.png" alt="" />
              </a>
            </li>
          </ul>
        </aside>

      </>
    )
  }
}

export default SideBarNewsInstaFeeds;