import React from "react";
import { Link } from "react-router-dom";
import "./FooterNavigation.css";

export default class FooterNavigation extends React.Component {
  render() {
    return (
      <>
    
        <div class="col-md-3 nav-footer">
          
            <div class="footer-tittle"  >
              <h4>Company Profile</h4>
              <ul>
                <li>
                  <Link to="/about">About Archrom Organics LLP</Link>
                </li>

                <li>
                  <li></li>
                  <Link to="/Management">Management</Link>
                </li>
                <li>
                  <Link to="/association">
                    Association
                  </Link>
                </li>
              </ul>
            </div>
          
        </div>

        <div class="col-md-2 nav-footer" >
          
            <div class="footer-tittle" >
              <h4>Inquiry</h4>
              <ul>
                <li>
                  <Link to="/inquiry">Contact Us</Link>
                </li>
                <li>
                  <Link to="/career">Careers</Link>
                </li>
              </ul>
            </div>
         
        </div>
        <div class="col-md-3 nav-footer">
          
            <div class="footer-tittle"  >
              <h4>Corporate Office</h4>
              <ul>
              <div class="media-body">
                     
                      <p>
                      607-612, Shilp Aperia, <br/>
                      Nr.Hotel Planet Landmark, Iscon-Ambali Road,
                      Ahmedabad - 380058, Gujarat
                      </p>
                    </div>
              </ul>
            </div>
          
        </div>
        <div class="col-md-3 nav-footer">
          
            <div class="footer-tittle">
              <h4>Factory Address</h4>
              <ul>
              <div class="media-body">
              
                      <p>
                      Plot No.T -71,72,<br/> Saykha Industrial Estate,
                      Taluka. Vagra, Dist. Bharuch-392140,
                      Gujrat, India
                      </p>
                    </div>
              </ul>
            </div>
          
        </div>
      
      </>
    );
  }
}
