// import React from "react";
// import { Link } from "react-router-dom";
// import "./Solutions.css";
// import ScriptTag from "react-script-tag";
// import $ from "jquery";
// import { FaFlask } from 'react-icons/fa';
// class Solutions extends React.Component {

//   render() {

//     return (
//       <div>
//         <main>
//           <div className="plane">
//             <div
//               class="slider-area2 section-bg2 solutions-slider"
//               style={{
//                 backgroundImage:
//                   "url(/assets/img/SolutionsAndDyes/productimage.jpg)",
//               }}
//             >
//               <div class="slider-height2 d-flex align-items-center">
//                 <div class="container">
//                   <div class="row">
//                     <div class="col-xl-12">
//                       <div class="hero-cap hero-cap2">
//                         <h2>Dyes & Intermediates</h2>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="container mt-50 mb-50">
//             <div className="row ">
//               <div
//                 className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 "
//                 id="selectProcess"
//               >
//                 <div className="blog_right_sidebar">
//                   <aside className="single_sidebar_widget post_category_widget">
//                     <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>
//                       PRODUCTS
//                     </h1>
//                   </aside>
//                 </div>
//               </div>

//               <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
//                 <div  style={{ textAlign: "center", alignContent: "center" }}>
//                   <div className="groove">
//                   <h1><FaFlask /></h1>
//                   <h3>Reactive Dyes</h3>
//                   <p>Application : Use for Textile & Digital Ink Industries</p>
//                   <p>
//                     (Cotton, viscose & Hosiery), digital Printing Ink (cartage &
//                     ball pen ink) (Saree & Dress Material, sutings &
//                     shirting’s-Shits, Socks, Bedsheets, Printing Scarf)
//                   </p>
//                   </div>
//                   <div className="groove">
//                   <h3>Acid Dyes</h3>
//                   <p>
//                     Application : Use for Textile, Cosmetics, Leather & Food
//                     Drugs Industries
//                   </p>
//                   <p>
//                     (Wood stain, Leather Industries, Cheque book Prints, Tennis
//                     ball, Skin-Fur Industries, Shoes Polishings, Swimwear, Food
//                     & Drugs, Cosmetics, Medicine, Coating, Textile Inks, Sports,
//                     suiting sweater, Blanket, Socks, Shawl, Silk sarees, Dress
//                     Material, Shirts)
//                   </p>
//                   </div>
//                   <div className="groove">
//                   <h3>Direct Dyes</h3>
//                   <p>Application : Used for Paper & Textile Industries </p>
//                   <p>
//                     {" "}
//                     (Furnishing, Hosiery, outdoor Tents, Light shades shirting,
//                     Paper & Pulp Industries, Cotton, Viscose, Silk, Jute, Linen
//                     Fabrics, dyeing & Printing on Fabrics, Ink-jet Printing,
//                     Digital Textile Printing, Textile Inks)
//                   </p>
//                   </div>
//                   <div className="groove">
//                   <h3>Solvent Dyes</h3>
//                   <p>
//                     Application : Used for Petroleum, Printing Inks & Plastic
//                     Industries.
//                   </p>
//                   <p>
//                     {" "}
//                     (Food Drugs, Lubricants, wax & candle thickness, Smoke, to
//                     colouring oils, spirit varnishes, Leather- spray finishing
//                     of dyed & undyed leather, colouring petrol, plastics resin,
//                     PVC, rubber, cosmetics, in various inks for rotogravure and
//                     rubber stereo printing, paper coating and typewriter
//                     rib-bons, ball point pen, Petroleum Acids, Aluminium foil
//                     printing, Wood Stain, Spirit & Varnishes, Varnishes,
//                     Polyamide, Digital Printing Inks, Plastic Colorants,
//                     Lacquer, PVC, PET-PTT, Nylon Filaments, Fluorescence in
//                     defence)
//                   </p>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
//               <div style={{ textAlign: "center", alignContent: "center" }}>
//               <div className="groove">
//                 <h3>Disperse Dyes</h3>
//                 <p>
//                   Application : Used for Textile, Digital Ink & Automotive
//                   Carpet Industries
//                 </p>
//                 <p>
//                   (Polyester, Polyester Blends, Acetate fiber, Polyamide,
//                   PET,PTT-Filament Treads & Fabrics, Linen fabric Dyeing &
//                   Printing, Digital Printing, Ink-Jet Printing, Textile Inks,
//                   shirts & T-shirts, Sport & Active wears, swimwear, suiting,
//                   carpet, narrow tape, sarees, dress material, undergarments of
//                   spandex)
//                 </p>
//                 </div>
//                 <div className="groove">
//                 <h3>Basic Dyes</h3>
//                 <p>
//                   Application : Used for Paper, Textile & Cosmetics Industries
//                 </p>
//                 <p>
//                   (Paper, Pulp & Crayon dyeing, Flexo-Inks, Wool, Silk, Soaps,&
//                   Detergents, Skin-fur, Cosmetics, Medicine, Wood Staining,
//                   Coating, Textile Inks, blanket, shirts, T-shirt, Carpet,
//                   socks, sarres)
//                 </p>
//                 </div>
//                 <div className="groove">
//                 <h3>Vat Dyes</h3>
//                 <p>Application : Used for High end Textile Industries </p>
//                 <p>
//                   (Food, Drugs, Cosmetics, Cellulose fibers specially for Cotton
//                   & cotton blends, Yarns, Jeans, Denims trichromatic dying &
//                   printing, Digital inks printing, Plastic colorant, Stains,
//                   Lacquer, PVC, PET-PTT, Nylon Filaments, Smokes & fluorescence
//                   in Defense, Bottom wear pants, beds sheets, defence & School
//                   uniform, terry towels, shirts)
//                 </p>
//                 </div>
//                 <div className="groove">
//                 <h3>Wool Reactive dyes - Lanasol Dyes</h3>
//                 <p>
//                   {" "}
//                   Application : Used for Used for Sports, Leisure wear & Textile
//                   Industries
//                 </p>
//                 <p>
//                   {" "}
//                   (Outdoor, Active & Athleisure wear), Active & Performance
//                   Wear, Formal Wear, Swimwear & Intimates, Carpet & Upholstery,
//                   Major applications include yarn dyeing (package and hanks),
//                   particularly in the chlorine-Hercosett process for
//                   machine-washable wool, loose stock and top dyeing)
//                 </p>
//                 </div>
//               </div>
//               </div>
//             </div>
//           </div>

//           {/* Solutions End */}
//           <div id="back-top">
//             <a title="Go to Top" href="#">
//               {" "}
//               <i class="fas fa-level-up-alt"></i>
//             </a>
//           </div>
//         </main>
//       </div>
//     );
//   }
// }

// export default Solutions;
import React from "react";
import { Link } from "react-router-dom";
import "./Solutions.css";
import ScriptTag from "react-script-tag";
import $ from "jquery";
import {
  AiTwotoneExperiment,
  AiFillExperiment,
  AiOutlineExperiment,
} from "react-icons/ai";
import {
  GiBubblingFlask,
  GiFizzingFlask,
  GiRoundBottomFlask,
  GiChemicalDrop,
  GiSoapExperiment,
} from "react-icons/gi";
import { TbChartDots3 } from "react-icons/tb";
import { RiArrowDownCircleFill } from "react-icons/ri";

class Solutions extends React.Component {
  state = {
    showCommercializeAcids: false,
  };

  toggleSetReactiveDyesInfoHandler = () => {
    const ReactiveDyesInfo = this.state.ShowReactiveDyesInfo;
    this.setState({ ShowReactiveDyesInfo: !ReactiveDyesInfo });
  };

  toggleSetAcidDyesInfoHandler = () => {
    const AcidDyesInfo = this.state.ShowAcidDyesInfo;
    this.setState({ ShowAcidDyesInfo: !AcidDyesInfo });
  };

  toggleSetDirectDyesInfoHandler = () => {
    const DirectDyesInfo = this.state.ShowDirectDyesInfo;
    this.setState({ ShowDirectDyesInfo: !DirectDyesInfo });
  };

  toggleSetSolventDyesInfoHandler = () => {
    const SolventDyesInfo = this.state.ShowSolventDyesInfo;
    this.setState({ ShowSolventDyesInfo: !SolventDyesInfo });
  };

  toggleSetDisperseDyesInfoHandler = () => {
    const DisperseDyesInfo = this.state.ShowDisperseDyesInfo;
    this.setState({ ShowDisperseDyesInfo: !DisperseDyesInfo });
  };

  toggleSetBasicDyesInfoHandler = () => {
    const BasicDyesInfo = this.state.ShowBasicDyesInfo;
    this.setState({ ShowBasicDyesInfo: !BasicDyesInfo });
  };

  toggleSetVatDyesInfoHandler = () => {
    const VatDyesInfo = this.state.ShowVatDyesInfo;
    this.setState({ ShowVatDyesInfo: !VatDyesInfo });
  };

  toggleSetLanasolDyesInfoHandler = () => {
    const LanasolDyesInfo = this.state.ShowLanasolDyesInfo;
    this.setState({ ShowLanasolDyesInfo: !LanasolDyesInfo });
  };

  render() {
    let SetReactiveDyesInfo = null;
    let SetAcidDyesInfo = null;
    let SetDirectDyesInfo = null;
    let SetSolventDyesInfo = null;
    let SetDisperseDyesInfo = null;
    let SetBasicDyesInfo = null;
    let SetVatDyesInfo = null;
    let SetLanasolDyesInfo = null;

    if (this.state.ShowReactiveDyesInfo) {
      SetReactiveDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-10" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>

          <ul class="list cat-list">
            <li>
              <h3>Application : Textile Industries, Cotton, Viscose, Hosiery, Linen fabric Dyeing & Printing, Ink-Jet Printing, Digital textile Printing, Textile Inks.</h3>{" "}
              <br></br>
             
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowAcidDyesInfo) {
      SetAcidDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>
                Application : Protein Fibers like Wool, Silk, Nylon, Skin-Fur, Leather Dyeing & Printing, Polishing, Cosmetics, Wood Stanning, Coating, Textile Inks.{" "}
              </h3>{" "}
              <br></br>
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowDirectDyesInfo) {
      SetDirectDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>Application : Paper & Pulp Industries, Cotton, Viscose, Silk, Jute, Linen fabric Dyeing & Printing, Ink-Jet Printing, Digital Textile Printing, Textile Inks.
</h3>{" "}
              <br></br>
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowSolventDyesInfo) {
      SetSolventDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>
                {/* Application : Cosmetics, Petroleum, Oil, Lubricants, Wax, Varnishes, Polyamide, Polyester, trichromatic Dyeing & Printing, Digital inks Printing, Plastic colorant, Wood stains, Lacquer, PVC, PET-PTT, Nylon Filaments, fluorescent Smoke.{" "} */}
              Coming Very Soon
              </h3>{" "}
              <br></br>
             
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowDisperseDyesInfo) {
      SetDisperseDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>
                Application : Polyester, Polyester blends, Acetate, Polyamide, PET, PTT- Filament threads & fabrics, Linen fabric Dyeing & Printing, Digital Printing, Ink-Jet Printing, Digital textile Printing, Textile Inks, Automotive Carpet Industries.
{" "}
              </h3>{" "}
              <br></br>
             
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowBasicDyesInfo) {
      SetBasicDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>
                {/* Application : Paper, Pulp & Crayon Dyeing, Flexo-inks, Wool, Silk, Soaps & Detergents, Skin-Fur, Cosmetics, Wood Stanning, Coating, Textile Inks.
{" "} */}
              Coming Very Soon
              </h3>{" "}
              <br></br>
              
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowVatDyesInfo) {
      SetVatDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>Application : Cosmetics, Cellulose fibres specially for Cotton & cotton blends, Yarns, Jeans, Denims trichromatic Dyeing & Printing, Digital Printing Inks, Plastic colorant, wood stains, Lacquer, PVC, PET-PTT, Nylon Filaments, fluorescent Smoke.</h3>{" "}
              <br></br>
              
            </li>
          </ul>
        </>
      );
    }
    if (this.state.ShowLanasolDyesInfo) {
      SetLanasolDyesInfo = (
        <>
          <h2>
            <RiArrowDownCircleFill />
          </h2>
          <h1 class="widget_title mt-20" style={{ color: "#2d2d2d;" }}>
            {" "}
          </h1>
          <ul class="list cat-list">
            <li>
              <h3>
                Application : Wool Dyeing, Carpet & Upholstery, Sports & Athleisure wear Textiles.{" "}
              </h3>{" "}
              <br></br>
             
            </li>
          </ul>
        </>
      );
    }

    return (
      <div>
        <main>
          <div className="plane">
            <div
              class="slider-area2 section-bg2 solutions-slider"
              style={{
                backgroundImage:
                  "url(/assets/img/SolutionsAndDyes/productimage.jpg)",
              }}
            >
              <div class="slider-height2 d-flex align-items-center">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="hero-cap hero-cap2">
                        <h2>Dyes & Intermediates</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-50 mb-50">
            <div className="row">
              <div
                className="col-xl-12 col-lg-12 col-sm-12 col-xs-12"
                id="selectProcess"
              >
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>
                      Products
                    </h1>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul
                      className="list cat-list"
                      style={{ textAlign: "center", alignContent: "center" }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              <button
                                style={{ padding: "10px 20px 10px 20px" }}
                                onClick={this.toggleSetAcidDyesInfoHandler}
                                class="btn"
                              >
                                <h1>
                                  <AiTwotoneExperiment />
                                </h1>
                                {!this.state.ShowAcidDyesInfo ? (
                                  <>Acid Dyes</>
                                ) : (
                                  <>Acid Dyes</>
                                )}
                              </button>
                              {SetAcidDyesInfo}
                            </li>
                            <li>
                              <button
                                style={{ padding: "10px 20px 10px 20px" }}
                                onClick={this.toggleSetBasicDyesInfoHandler}
                                class="btn"
                              >
                                <h1>
                                  <GiSoapExperiment />
                                </h1>
                                {!this.state.ShowBasicDyesInfo ? (
                                  <>Basic Dyes</>
                                ) : (
                                  <>Basic Dyes</>
                                )}
                              </button>
                              {SetBasicDyesInfo}
                            </li>
                            <li>
                              <button
                                style={{ padding: "10px 20px 10px 20px" }}
                                onClick={this.toggleSetDirectDyesInfoHandler}
                                class="btn"
                              >
                                <h1>
                                  <TbChartDots3 />
                                </h1>
                                {!this.state.ShowDirectDyesInfo ? (
                                  <>Direct Dyes</>
                                ) : (
                                  <>Direct Dyes</>
                                )}
                              </button>
                              {SetDirectDyesInfo}
                            </li>
                            <li>
                              <button
                                style={{ padding: "10px 20px 10px 20px" }}
                                onClick={this.toggleSetDisperseDyesInfoHandler}
                                class="btn"
                              >
                                <h1>
                                  <GiChemicalDrop />
                                </h1>
                                {!this.state.ShowDisperseDyesInfo ? (
                                  <>Disperse Dyes</>
                                ) : (
                                  <>Disperse Dyes</>
                                )}
                              </button>
                              {SetDisperseDyesInfo}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul
                      className="list cat-list"
                      style={{ textAlign: "center", alignContent: "center" }}
                    >
                      <li>
                        <button
                          style={{ padding: "10px 20px 10px 20px" }}
                          onClick={this.toggleSetSolventDyesInfoHandler}
                          class="btn"
                        >
                          <h1>
                            <GiBubblingFlask />
                          </h1>
                          {!this.state.ShowSolventDyesInfo ? (
                            <>Solvent Dyes</>
                          ) : (
                            <>Solvent Dyes</>
                          )}
                        </button>
                        {SetSolventDyesInfo}
                      </li>

                      <li>
                        <button
                          style={{ padding: "10px 20px 10px 20px" }}
                          onClick={this.toggleSetVatDyesInfoHandler}
                          class="btn"
                        >
                          <h1>
                            <GiFizzingFlask />
                          </h1>
                          {!this.state.ShowVatDyesInfo ? (
                            <>Vat Dyes</>
                          ) : (
                            <>Vat Dyes</>
                          )}
                        </button>
                        {SetVatDyesInfo}
                      </li>
                      <li>
                        <button
                          style={{ padding: "10px 20px 10px 20px" }}
                          onClick={this.toggleSetReactiveDyesInfoHandler}
                          class="btn"
                        >
                          <h1>
                            <AiFillExperiment />
                          </h1>
                          {!this.state.ShowReactiveDyesInfo ? (
                            <>Reactive Dyes </>
                          ) : (
                            <>Reactive Dyes</>
                          )}
                        </button>
                        {SetReactiveDyesInfo}
                      </li>

                      <li>
                        <button
                          style={{ padding: "10px 20px 10px 20px" }}
                          onClick={this.toggleSetLanasolDyesInfoHandler}
                          class="btn"
                        >
                          <h1>
                            <AiOutlineExperiment />
                          </h1>
                          {!this.state.ShowLanasolDyesInfo ? (
                            <>Lanasol Dyes - (Wool Reactive dyes)</>
                          ) : (
                            <>Lanasol Dyes - (Wool Reactive dyes)</>
                          )}
                        </button>
                        {SetLanasolDyesInfo}
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>

          {/* Solutions End */}
          <div id="back-top">
            <a title="Go to Top" href="#">
              {" "}
              <i class="fas fa-level-up-alt"></i>
            </a>
          </div>
        </main>
      </div>
    );
  }
}

export default Solutions;
