import React from 'react';
import './NewsArticles.css';

class NewsArticles extends React.Component {
  render() {
    return (
      <>
        <article class="blog_item">
          <div class="blog_item_img">
            <img class="card-img rounded-0" src="assets/img/blog/single_blog_1.png" alt="" />
            <a href="#" class="blog_item_date">
              <h3>24</h3>
              <p>Sep</p>
            </a>
          </div>
          <div class="blog_details">
            <a class="d-inline-block" href="#">
              <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Indian chemical Exhibition from 24th To 25th september, 2013</h2>
            </a>
            <p>
              A K Industry is going to participate in Indian chemical Exhibition from 24th To 25th september, 2013 at Hotel hilton,sydney city,Australia and we invite our customers to visit us at Booth No.04
            </p>
            <ul class="blog-info-link">
              {/* <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li> */}
              {/* <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li> */}
            </ul>
          </div>
        </article>
        <article class="blog_item">
          <div class="blog_item_img">
            <img class="card-img rounded-0" src="assets/img/blog/single_blog_2.png" alt="" />
            <a href="#" class="blog_item_date">
              <h3>15</h3>
              <p>Jan</p>
            </a>
          </div>
          <div class="blog_details">
            <a class="d-inline-block" href="#">
              <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Google inks pact for new 35-storey office</h2>
            </a>
            <p>That dominion stars lights dominion divide years for fourth have don't stars is that
              he earth it first without heaven in place seed it second morning saying.</p>
            <ul class="blog-info-link">
              <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
              <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
            </ul>
          </div>
        </article>
        <article class="blog_item">
          <div class="blog_item_img">
            <img class="card-img rounded-0" src="assets/img/blog/single_blog_3.png" alt="" />
            <a href="#" class="blog_item_date">
              <h3>15</h3>
              <p>Jan</p>
            </a>
          </div>
          <div class="blog_details">
            <a class="d-inline-block" href="#">
              <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Google inks pact for new 35-storey office</h2>
            </a>
            <p>That dominion stars lights dominion divide years for fourth have don't stars is that
              he earth it first without heaven in place seed it second morning saying.</p>
            <ul class="blog-info-link">
              <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
              <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
            </ul>
          </div>
        </article>
        <article class="blog_item">
          <div class="blog_item_img">
            <img class="card-img rounded-0" src="assets/img/blog/single_blog_4.png" alt="" />
            <a href="#" class="blog_item_date">
              <h3>15</h3>
              <p>Jan</p>
            </a>
          </div>
          <div class="blog_details">
            <a class="d-inline-block" href="blog_details.html">
              <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Google inks pact for new 35-storey office</h2>
            </a>
            <p>That dominion stars lights dominion divide years for fourth have don't stars is that
              he earth it first without heaven in place seed it second morning saying.</p>
            <ul class="blog-info-link">
              <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
              <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
            </ul>
          </div>
        </article>
        <article class="blog_item">
          <div class="blog_item_img">
            <img class="card-img rounded-0" src="assets/img/blog/single_blog_5.png" alt="" />
            <a href="#" class="blog_item_date">
              <h3>15</h3>
              <p>Jan</p>
            </a>
          </div>
          <div class="blog_details">
            <a class="d-inline-block" href="blog_details.html">
              <h2 class="blog-head" style={{ color: '#2d2d2d' }}>Google inks pact for new 35-storey office</h2>
            </a>
            <p>That dominion stars lights dominion divide years for fourth have don't stars is that
              he earth it first without heaven in place seed it second morning saying.</p>
            <ul class="blog-info-link">
              <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
              <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
            </ul>
          </div>
        </article>
      </>
    )
  }
}

export default NewsArticles;