import React from "react";
// import SideNavigationACQRG from '../SideNavigationACQRG/SideNavigationACQRG';
import "./Management.css";

class Management extends React.Component {
  render() {
    return (
      <div>
        <main>
          {/* <!--? Hero Start --> */}
          <div
            className=" about-slider slider-area2 section-bg2"
            style={{
              backgroundImage: "url(/assets/img/About/AboutUsSlider03.jpg)",
            }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Management</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- About Us Start --> */}

          <section className="blog_area " style = {{paddingTop : 30}}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div className="blog_left_sidebar">
                    <article className="blog_item">
                      
                      <div className="blog_details text-justify">
                        <div className="d-inline-block">
                          <h2
                            className="blog-head"
                            style={{ color: "#2d2d2d" }}
                          >
                            Our Management
                          </h2>
                        </div>
                        <p>
                          <b>
                            Mr. Jankhit C Prajapati , Designated Partner @
                            Archrom Organics LLP{" "}
                          </b>
                          , has been in the business of land and real estate
                          development since past 20 years and Director of M/s.
                          Prajapati Buildcon Pvt. Ltd., Ahmedabad. He
                          contributes to the Company with his strengths in the
                          areas of finance, liaison and building customer
                          relationships. <br></br><br></br>
                          <b>
                            {" "}
                            Mr. Ashok K. Patel, Designated Partner @ Archrom
                            Organics LLP
                          </b>
                          , has been in the business of manufacturing Dyes &
                          Intermediates since past 22 years and Owner/Partner of
                          M/s. A.K. Industries, Vatva, Ahmedabad. He contributes
                          to the Company with his strengths in Sales &
                          Marketing, Product application, customer relations and
                          establishing dealer/distributor network, which will
                          help the company immensely to cut down on Market
                          Development costs and start generating revenues
                          without any lead time of building new clients.
                        <br></br><br></br>
                          <b>
                            {" "}
                            Mr. Balkrishna S. Bhosale, Designated Partner @
                            Archrom Organics LLP
                          </b>
                          , is a technocrat with an M.Sc. in Organic Chemistry
                          and pursuing his Ph.D through distance education. A
                          veteran with over 32 years of experience in R&D,
                          Production and new Plant operations, his strengths
                          offer a huge advantage to the company to develop new
                          products with technical applications and also provide
                          consistency in quality and supplies.
                          <br></br><br></br>
                         <b>
                            {" "}
                            Mr. Rohit B. Bhosale, Designated Partner @ Archrom
                            Organics LLP
                            </b>
                          , is a BE in Chemical Engineering and brings his
                          experience of over 5 years in managing production in
                          Synthesis division, formulations and his knowledge of
                          inspection of equipment with safety standards.
                         
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                {/* <SideNavigationACQRG /> */}
              </div>
            </div>
          </section>
          {/* <!-- About Us End --> */}
          <div id="back-top">
            <a title="Go to Top" href="#">
              {" "}
              <i className="fas fa-level-up-alt"></i>
            </a>
          </div>
        </main>
      </div>
    );
  }
}
export default Management;
