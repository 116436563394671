import React from 'react';
import SideNavigationTDC from '../SideNavigationTDC/SideNavigationTDC';
import './DigitalTextilePrinting.css';

class DigitalTextilePrinting extends React.Component {
  render() {
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            class="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/hero/hero2.png)" }}
          >
            <div class="slider-height2 d-flex align-items-center">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="hero-cap hero-cap2">
                      <h2>Digital Textile Printing</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- About Us Start --> */}

          <section class="blog_area section-padding">
            <div class="container">
              <div class="row">
                <div class="col-xl-8 col-lg-8 mb-5 mb-lg-0">
                  <div class="blog_left_sidebar">
                    <article class="blog_item">
                      <div class="blog_details text-justify">
                        <div class="d-inline-block">
                          <h2 class="blog-head" style={{ color: '#2d2d2d;' }}>
                            DIGITAL TEXTILE PRINTING
                          </h2>
                        </div>
                        <p>
                          Solunaris GmbH, located in the heart of the Rhine-Main area in the industry Kalle-Albert, Wiesbaden, was founded in early 2010 with the goal to provide our discerning and global customers with quality products and services for Digital Textile Printing as well as for the traditional Dyeing and Printing industry. <br /><br />
                          Art production and quality assurance technologies at Solunaris guarantee consistently high product quality. The dedicated team at Solunaris has in-depth knowledge and extensive international experience to offer advice and support to help overcome complex challenges in the textile printing industry. <br /><br />
                          Solunaris offers a comprehensive range of Antelos® inks "Made in Germany" for Digital Textile Printing on natural and synthetic fibers and thus cover all conventional print head technologies. <br /><br />
                          Please visit www.solunaris.com for more information.
                        </p>
                      </div>
                    </article>
                  </div>
                </div>
                <SideNavigationTDC />
              </div>
            </div>
          </section>
          <div class="gallery-area">
            <div class="container p-0 fix">
              <div class="row no-gutters">
                <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="single-gallery text-center">
                    <div class="gallery-img" style={{ backgroundImage: "url(/assets/img/gallery/gallery1.png)" }}></div>
                    <div class="thumb-content-box">
                      <div class="thumb-content">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="single-gallery text-center">
                    <div class="gallery-img" style={{ backgroundImage: "url(assets/img/gallery/gallery2.png)" }}></div>
                    <div class="thumb-content-box">
                      <div class="thumb-content">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                  <div class="single-gallery text-center">
                    <div class="gallery-img" style={{ backgroundImage: "url(assets/img/gallery/gallery3.png)" }}></div>
                    <div class="thumb-content-box">
                      <div class="thumb-content">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* <!-- About Us End --> */}

      </div>
    )
  }
}
export default DigitalTextilePrinting;