import React, { Component } from 'react';
import './AcidDyes.css';

class AcidDyes extends React.Component {
  state = {
    showCommercializeProducts: false,
    showPilotBasisProducts: false
  }

  toggleCommercializedProductsHandler = () => {
    const doShowCommercializeProducts = this.state.showCommercializeProducts;
    this.setState({ showCommercializeProducts: !doShowCommercializeProducts });
  }

  togglePilotBasisProductsHandler = () => {
    const doShowPilotBasisProducts = this.state.showPilotBasisProducts;
    this.setState({ showPilotBasisProducts: !doShowPilotBasisProducts });
  }

  render() {

    let commercializeProducts = null;
    let pilotBasisProducts = null;

    if (this.state.showCommercializeProducts) {
      commercializeProducts = (
        <>
          <h1 className="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Commercialized Products </h1>
          <ul className="list cat-list" >
            <li>
              <h3>Acid Red 254</h3>
            </li>
            <li>
              <h3>Acid Red 1</h3>
            </li>
            <li>
              <h3>Acid Red 33</h3>
            </li>
            <li>
              <h3>Acid Red 17</h3>
            </li>
            <li>
              <h3>Acid Red 251</h3>
            </li>
            <li>
              <h3>Acid Red 37</h3>
            </li>
            <li>
              <h3>Acid Blue 171</h3>
            </li>
            <li>
              <h3>Acid Blue 277</h3>
            </li>
          </ul>
        </>
      )
    }

    if (this.state.showPilotBasisProducts) {
      pilotBasisProducts = (
        <>
          <h1 className="widget_title mt-20" style={{ color: '#2d2d2d;' }}> Pilot Based Products </h1>
          <ul className="list cat-list" >
            <li>
              <h3>Acid Black 63</h3>
            </li>
            <li>
              <h3>Acid Red 357</h3>
            </li>
            <li>
              <h3>Acid Red 226</h3>
            </li>
            <li>
              <h3>Acid Red 405</h3>
            </li>
            <li>
              <h3>Acid Black 84</h3>
            </li>
            <li>
              <h3>Acid Red 337</h3>
            </li>
            <li>
              <h3>Acid Red 182</h3>
            </li>
            <li>
              <h3>Acid Black 139</h3>
            </li>
            <li>
              <h3>Acid Brown 365</h3>
            </li>
            <li>
              <h3>Acid Brown 75</h3>
            </li>
            <li>
              <h3>Acid Blue 133</h3>
            </li>
            <li>
              <h3>Acid Green 73</h3>
            </li>
            <li>
              <h3>Acid Red 34</h3>
            </li>
            <li>
              <h3>Acid Red 18</h3>
            </li>
            <li>
              <h3>Acid Blue 324</h3>
            </li>
            <li>
              <h3>Acid Red 131</h3>
            </li>
            <li>
              <h3>Acid Red 118</h3>
            </li>
            <li>
              <h3>Acid Blue 40</h3>
            </li>
          </ul>
        </>
      )
    }
    return (
      <div>

        <main>
          {/* <!--? Hero Start --> */}
          <div
            className="slider-area2 section-bg2"
            style={{ backgroundImage: "url(/assets/img/SolutionsAndDyes/AcidDyesSlider.jpg)" }}
          >
            <div className="slider-height2 d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="hero-cap hero-cap2">
                      <h2>Acid Dyes</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Hero End --> */}
          {/* <!-- Reactive dyes content start --> */}

          <div className="categories-area section-padding40 gray-bg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="section-tittle text-justify">
                    <span className="element">What we offer</span>
                    <h2>ACID DYES</h2>
                    <p>
                      These are Monosulphonated 1:2 Metal Complex Dyes characterized by Excellent Fastness to light, Suitable for the production of medium & heavy shades for automotive end-uses where high fastness is of prime importance, Excellent Compatibility, Good wet fastness, Wide shade gamut, Good level dyeing properties, on Polyamide, optimum coverage of barre when dyes are applied at elevated temperatures (105 - 120 Deg. C.), Good partition on Wool / Nylon blends, Suitable for low-temperature dyeing, Suitable for dyeing of all-fiber blends with wool, Selected dyes suitable for resist and displacement printing of carpets. 
                      </p>
                      <p>
                      Pick your product in the table below, then request a product from the applicable range of Acid Dyes to get specifics and a downloadable brochure for your particular requirement. Information on the recommended coloration method is also given in the booklet to have all the knowledge you need to meet your needs.
                   
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reactiv dyes content end --> */}
          {/* <!-- Reactive Dye selection start --> */}
          <div className="container mt-50 mb-50">
            <div className="row">

              <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12" id="selectProcess">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <h1 style={{ color: "#2d2d2d;", textAlign: "center" }}>STATUS</h1>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul className="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <div className="container">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <li>
                              {/* <h2 style={{ color: "#2d2d2d;", textAlign: "center" }}>Commercialize</h2> */}
                              <div className="container">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <button style={{ padding: '10px 20px 10px 20px' }} onClick={this.toggleCommercializedProductsHandler} className="btn">
                                      {
                                        !this.state.showCommercializeProducts ?
                                          <>Show Commercialized Products</> :
                                          <>Hide Commercialized products</>
                                      }
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {commercializeProducts}
                            </li>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </aside>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-xs-12">
                <div className="blog_right_sidebar">
                  <aside className="single_sidebar_widget post_category_widget">
                    <ul className="list cat-list" style={{ textAlign: "center", alignContent: "center" }}>
                      <li>
                        <button style={{ padding: '10px 20px 10px 20px', alignContent: 'center' }} onClick={this.togglePilotBasisProductsHandler} className="btn">
                          {
                            !this.state.showPilotBasisProducts ?
                              <>Show Pilot Based Products</> :
                              <>Hide Pilot Based Products</>
                          }
                        </button>
                        {pilotBasisProducts}
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>

            </div>
          </div>

          {/* <!-- Reactive Dye selection end --> */}
          <div id="back-top">
            <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
          </div>
        </main>
      </div >
    )
  }
}

export default AcidDyes;